/* @import url("https://fonts.googleapis.com/css2?family=Sen:wght@400;700;800&display=swap"); */
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  scroll-behavior: smooth;
  /* cursor: url(../assets/cursor.svg), url(../assets/cursor.jpeg),
    url(../assets/cursor.png), auto; */
  scroll-timeline: scroll-page block;
}

.animated {
  animation-timeline: scroll-page;
}

.fadeIn {
  @apply animate-fadeIn ease-in-out;
  animation-fill-mode: both;
  animation-delay: 4s;
  /* animation-timeline: scroll-page; */
}

h1,
h2,
h3,
h4,
h5,
h6,
label,
span,
thead {
  font-family: "Clash Display", sans-serif;
}

p,
button,
a,
input,
td {
  font-family: "Satoshi", sans-serif;
}
input {
  outline: none;
}
.hover-state:hover {
  opacity: 50%;
  transition-duration: 900ms;
}
.bg {
  background-image: url(../assets/bg.png);
  background-position: center;
  background-size: cover;
  position: relative;
}

.tooltip {
  border-radius: 0.25rem;
  background: #26313c;
  color: #fff;
  padding: 1rem;
  box-shadow: 15px, 30px, 40px, 5px, rgba(0, 0, 0, 0.5);
  text-align: center;
}

.fadein {
  visibility: visible;
  opacity: 1;
  transition: opacity 2s linear;
}
.fadeout {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 2s, opacity 2s linear;
}
.list {
  list-style: circle;
}

.animate-delay {
  animation-delay: 6000ms;
}
.banner {
  /* content: "";
  position: absolute;
  width: 100%;
  height: 100%; */
  border-radius: 20px;
  background: radial-gradient(
      100% 147.03% at 100% 0%,
      #00d5ff -10%,
      rgba(0, 213, 255, 0) 70%
    ),
    radial-gradient(
      38.28% 69.37% at 34.4% -33.18%,
      rgba(152, 237, 222, 0.82) 0%,
      rgba(0, 255, 209, 0) 100%
    ),
    linear-gradient(
      125deg,
      #1188ff 15.21%,
      #8000ff 49.68%,
      #aa00ff 79.11%,
      #ff00e6 118.34%
    );
  /* left: 0;
  right: 0;
  bottom: 0;
  top: 0; */
  filter: contrast(150%);
}

.dotted {
  position: absolute;
  top: -12px;
  left: 18px;
  width: 200px;
}
.bubble::after {
  content: "";
  border-top-left-radius: 50% 100%;
  border-top-right-radius: 50% 100%;
  position: absolute;
  bottom: 0;
  height: 85%;
  width: 100%;
  background-color: #fff;
  z-index: -1;
}

.no__scroll::-webkit-scrollbar {
  display: none;
}

.no__scroll {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.light-grey {
  @apply text-gray-300;
}

.button {
  @apply bg-[#b2ffce] text-sm rounded-md p-2 px-5 text-center text-black;
}

button {
  @apply hover:scale-105 duration-700;
}

.body-shadow {
  box-shadow: 0px 0px 0px 4px #9cdfad3d;
  border: 1px solid #3cbe62;
}

.card {
  @apply border-[0.5px] hover:scale-90 duration-500 border-gray-600 rounded p-3 lg:p-5 relative hover:cursor-pointer;
}

.border-gradient {
  border: 10px solid;
  border-image-slice: 1;
  border-width: 5px;
}

.border-gradient-green {
  border-image-source: linear-gradient(to left, #00c853, #b2ff59);
}

@keyframes glowing {
  0% {
    text-shadow: 0 0 5px #b2ffce, 0 0 10px #b2ffce, 0 0 20px #b2ffce,
      0 0 40px #ce93d8c7, 0 0 80px #ce93d8c7, 0 0 90px #ce93d8c7;
  }
  50% {
    text-shadow: 0 0 10px #ce93d8c7, 0 0 20px #ce93d8c7, 0 0 30px #ce93d8c7,
      0 0 60px #10b981, 0 0 120px #10b981, 0 0 130px #10b981;
  }
  100% {
    text-shadow: 0 0 5px #b2ffce, 0 0 10px #b2ffce, 0 0 20px #b2ffce,
      0 0 40px #ce93d8c7, 0 0 80px #ce93d8c7, 0 0 90px #ce93d8c7;
  }
}

/* Apply the glowing animation to the text */
.text-glow {
  animation: glowing 4s infinite;
  @apply text-black/40;
}

.white_banner {
  background-color: rgb(255 255 255 / 0.9);
}

.gradient__btn {
  @apply bg-gradient-to-r from-[#34d399] via-[#b2ffce] to-[#10b981] animate-gradient-bg bg-200%;
}
